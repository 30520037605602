import { PrivaStatus } from '@priva/styles/foundation';

import { ErrorDetail, ErrorType, UserNotification } from './notification.interface';

const unknownError: ErrorDetail = {
    id: 'UNKNOWN_ERROR',
    type: 'error',
    message: 'Unknown error',
    messageKey: 'APP.ERROR_MESSAGE.UNKNOWN_ERROR',
    title: 'APP.ERROR_TITLE.UNEXPECTED',
};

interface ErrorDtoNextApiFormat {
    exceptionMessage?: string;
    status: number;
    title?: string;
    type?: string;
    details?: any;
}

interface ErrorDtoIdFormat {
    id?: string;
    status: number;
    message: string;
    name?: string;
    url?: string;
}

interface ErrorDtoDetailFormat {
    detail: string[] | { msg: string }[];
    type: ErrorType;
}

interface ErrorDtoTitleFormat {
    type: string;
    title: string;
    status?: string;
    traceId?: string;
    errors?: { [name: string]: string[] };
    address?: string[];
}

function isErrorNextApiFormat(error: ErrorDtoNextApiFormat | any): error is ErrorDtoNextApiFormat {
    return typeof error && (error?.exceptionMessage || error?.message);
}

function isErrorMessageFormat(error: ErrorDtoIdFormat | any): error is ErrorDtoIdFormat {
    return error && error?.message && error?.status;
}

function isErrorIdArrayFormat(error: ErrorDtoIdFormat[] | any): error is ErrorDtoIdFormat[] {
    return error[0] && error[0]?.id && error[0]?.status;
}

function isErrorDetailFormat(error: ErrorDtoDetailFormat | any): error is ErrorDtoDetailFormat {
    return typeof error && error?.detail;
}

function isErrorTitleFormat(error: ErrorDtoTitleFormat | any): error is ErrorDtoTitleFormat {
    return typeof error && error?.title;
}

export function getErrors(errors: any, resourceKey?: string): ErrorDetail[] {
    if (!errors) {
        return [unknownError];
    }
    if (isErrorNextApiFormat(errors?.error)) {
        return errorNextApiFormatToErrorDto(errors.error, resourceKey);
    } else if (isErrorTitleFormat(errors?.error)) {
        return errorTitleToErrorDto(errors.error);
    } else if (isErrorMessageFormat(errors)) {
        return errorIdFormatToErrorDto([errors]);
    } else if (isErrorIdArrayFormat(errors)) {
        return errorIdFormatToErrorDto(errors);
    } else if (isErrorTitleFormat(errors)) {
        return errorTitleToErrorDto(errors);
    } else if (isErrorDetailFormat(errors)) {
        return errorDetailFormatToErrorDto(errors);
    } else {
        return errors?.message
            ? [
                  {
                      ...unknownError,
                      message: errors?.message,
                      messageKey: errors.message,
                  },
              ]
            : [unknownError];
    }
}

// map next api detail format to errorDetail format
function errorNextApiFormatToErrorDto(error: ErrorDtoNextApiFormat, resourceKey?: string): ErrorDetail[] {
    const errorDto: ErrorDetail = {
        status: error.status,
        type: 'error',
        message: error.exceptionMessage || error.title,
        messageKey: resourceKey || error.exceptionMessage,
        title: errorTypeToTitleKey(error.type) || error.title,
        details: error.details,
    };
    return [errorDto];
}

// map id format to errorDetail format
function errorIdFormatToErrorDto(errors: ErrorDtoIdFormat[]): ErrorDetail[] {
    const errorDtos: ErrorDetail[] = [];
    // specific errors fatal due to notification handling
    if (errors[0].id === 'DATABASE_COMMUNICATION_ISSUE') {
        errorDtos.push({
            id: errors[0].id,
            status: errors[0].status,
            type: 'fatal',
            message: errors[0].message,
            messageKey: `APP.ERROR_MESSAGE.${errors[0].id}`,
        });
    } else {
        errors.forEach((e) => {
            errorDtos.push({
                id: e.id,
                status: e.status,
                type: e?.status === 504 ? 'fatal' : 'error',
                message: e.message,
                messageKey: e.id ? `APP.ERROR_MESSAGE.${e.id}` : e.message,
            });
        });
    }
    return errorDtos;
}

// map (deprecated) detail format to errorDetail format
function errorDetailFormatToErrorDto(errors: ErrorDtoDetailFormat): ErrorDetail[] {
    const errorDtos: ErrorDetail[] = [];
    if (typeof errors.detail === 'string') {
        errorDtos.push({
            id: undefined,
            type: errors.type || 'error',
            message: errors.detail,
            messageKey: errors.detail,
        });
    } else {
        errors.detail.forEach((detail) =>
            errorDtos.push({
                id: undefined,
                type: errors.type || 'error',
                message: detail.msg || detail,
                messageKey: detail.msg || detail,
            }),
        );
    }
    return errorDtos;
}

// map key-values (400) format to errorDetail format
// error title check must be before error message check
function errorTitleToErrorDto(error: ErrorDtoTitleFormat, resourceKey?: string): ErrorDetail[] {
    const errorDtos: ErrorDetail[] = [];
    if (error.errors) {
        Object.values(error.errors).forEach((values) =>
            values.forEach((e) =>
                errorDtos.push({
                    id: error.traceId,
                    type: 'error',
                    message: e,
                    messageKey: resourceKey || e,
                }),
            ),
        );
    } else {
        const message = `${error.title} (${error.status})`;
        errorDtos.push({
            id: error?.traceId,
            type: 'error',
            message,
            messageKey: resourceKey || message,
        });
    }
    return errorDtos;
}

function errorTypeToPrivaStatus(errorType: string): PrivaStatus {
    switch (errorType) {
        case 'error':
            return 'Danger';
        case 'warning':
            return 'Warning';
        case 'success':
            return 'Success';
        case 'info':
            return 'Info';
        default:
            return 'Danger';
    }
}

function errorTypeToTitleKey(errorType: string): string {
    switch (errorType) {
        case '/problems/unauthorized':
            return 'APP.ERROR_TITLE.UNAUTHORIZED';
        case '/problems/generic':
            return 'APP.ERROR_TITLE.GENERIC_ISSUE';
        case '/problems/datapoint':
            return 'APP.ERROR_TITLE.DATAPOINT_ISSUE';
        case '/problems/cannot-map-protocol':
            return 'APP.ERROR_TITLE.PROTOCOL_ISSUE';
        case '/problems/not-unique':
            return 'APP.ERROR_TITLE.NOT_UNIQUE_ISSUE';
        case '/problems/validation':
            return 'APP.ERROR_TITLE.VALIDATION_ISSUE';
        case '/problems/net-topology':
            return 'APP.ERROR_TITLE.ZONE_ISSUE';
        case '/problems/internal-error':
            return 'APP.ERROR_TITLE.UNEXPECTED';
        default:
            return undefined;
    }
}

export function getUserNotification(error: ErrorDetail, emphasizedKey?: string): UserNotification {
    return {
        emphasizedText: emphasizedKey || error.title,
        text: error.messageKey,
        status: errorTypeToPrivaStatus(error.type),
    };
}
